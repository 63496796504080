import React, { Component } from 'react'
import eventicon from './media/calendaricon.png';
import eventlocation from './media/location.png';
import eventmask from './media/tilemask.png';
import TicketList from './TicketList';
import Drawer from 'react-drag-drawer';
import './Popupstyle.css';
import { BoxLoading } from 'react-loadingg';
import QRCode from "react-qr-code";

export class Event extends Component {

    constructor(props){
        super(props);
        this.state = { code: props.code, logged: props.logged, notExists: false, eventInfo: null, 
            selectedSection: 0, eventTickets: null, openLoader: false, showMyTickets: false, myTickets: [] };
    }

    static getDerivedStateFromProps(props, current_state) {
        if (current_state.code !== props.code || current_state.logged !== props.logged ) {
            
          return {
            code: props.code,
            logged: props.logged
          }
          
        }

        return null;
    }

    componentDidUpdate(prevProps){
        if ( prevProps.logged !== this.props.logged ) {
            this.getEventInfo(); 
        }
      }

    componentDidMount () {
    
       this.getEventInfo();
    }
  
    getEventInfo = () => {
        var sessiontoken = localStorage.getItem('session_token');

        const requestOptions = {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/x-www-form-urlencoded',
            }),
            body: JSON.stringify({
              token: sessiontoken,
              code: this.state.code
            })
            };
          
            const apiUrl = 'https://api.shami.app/public/v1/event/code';
      
          fetch(apiUrl, requestOptions)
          .then(r => r.json())
          .then(r => {           
            console.log(r);
            if(r.info == null){
                this.setState({ notExists: true });
            }else{
                this.setState({ eventInfo: r.info, eventTickets: r.tickets, myTickets: r.my_tickets  });
                if(r.my_tickets.length>0){
                    this.setState({ showMyTickets: true });
                }
            }
          }).catch(e => {
              this.setState({ notExists: true });
          });
    }

    goToPageProfile = () => {
        var newLocation = "/"+this.state.eventInfo.profile_code;
      window.location.href = newLocation;
      return;
    }

    selectedSection = (index) => {
        if(index == 2){
            this.setState({ showMyTickets: true });
            return;
        }

        this.setState({ selectedSection: index });
        if(index == 0){
           
        }else{
           
        }
        
    }

    buyTicket = (ticketid, quantity) => {
        if(!this.state.logged){
            this.props.showLogin();
            return;
        }

        var sessiontoken = localStorage.getItem('session_token');
        this.setState({ openLoader: true });
        const requestOptions = {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/x-www-form-urlencoded',
            }),
            body: JSON.stringify({
              token: sessiontoken,
              ticket: ticketid,
              quantity: quantity
            })
            };
          
            const apiUrl = 'https://api.shami.app/public/v1/event/buy';
      
          fetch(apiUrl, requestOptions)
          .then(r => r.json())
          .then(r => {      
            if(r.payment == null){
                this.setState({ openLoader: false });
            }else{
                window.location.href = r.payment;
                return;
            }
          }).catch(e => {
            this.setState({ openLoader: false });
          });
    }

    closeMyTickets = () => {
        this.setState({ showMyTickets: false });
    }
   
    getDate = (string) => {
      var eventDate = new Date(string);
      var textualDate = new Intl.DateTimeFormat("en-US", {
          year: "numeric",
        }).format(eventDate);
        textualDate += '/';
        textualDate += new Intl.DateTimeFormat("en-US", {
          month: "2-digit",
        }).format(eventDate);
        textualDate += '/';
        textualDate += new Intl.DateTimeFormat("en-US", {
          day: "2-digit",
        }).format(eventDate);
        textualDate += ' ';
        textualDate += new Intl.DateTimeFormat("it-IT", {
          hour: "numeric",
          minute: "numeric"
        }).format(eventDate);
        
      return textualDate;
    }

    render() {
        return (
            <div>
                
                {this.state.eventInfo != null &&
              <div>
                <div   style={{width: '100%'}}>
                <div style={{width: '100%', height: 500, left: 0, top: 0}}>
                <img src={this.state.eventInfo.event_image} width="100%" height="480" style={{ filter: 'blur(8px)', WebkitFilter: 'blur(8px)', objectFit: 'cover', borderBottomRightRadius: '0px', borderBottomLeftRadius: '0px'}} />
                </div>
               
                <div   style={{width: '100%', alignContent: 'left', alignItems: 'left', display: 'flex', flexDirection: 'column', height: '290px', top: '300px', position: 'absolute', backgroundColor: 'rgb(255,255,255)', borderTopRightRadius: '80px', borderTopLeftRadius: '80px' }}>         
                <div  style={{ alignContent: 'left', alignItems: 'center', height: '50px', marginLeft: '20px', marginTop: '80px', display: 'flex'}}>
                <div style={{ marginLeft: '20px', color: 'rgb(50,50,50)', fontWeight: '800', fontSize: '22px' }}>{this.state.eventInfo.name}</div>  
                </div> 

                <div  style={{ alignContent: 'left', alignItems: 'center', height: '50px', marginLeft: '40px', marginTop: '10px', display: 'flex'}}>
                <img src={eventlocation} width="25px" height="25px" style={{ objectFit: 'cover', borderRadius: '25px'}} />
                <div style={{ marginLeft: '15px', textAlign: 'left', color: 'rgb(50,50,50)', fontWeight: '600', fontSize: '12px' }}>{this.state.eventInfo.event_place}</div>  
                </div>  

                <div  style={{ alignContent: 'left', alignItems: 'center', height: '50px', marginLeft: '40px', marginTop: '5px', display: 'flex'}}>
                <img src={eventicon} width="22px" height="22px" style={{ objectFit: 'cover', marginLeft: '4px', borderRadius: '25px'}} />
                <div style={{ marginLeft: '15px',  textAlign: 'left',color: 'rgb(50,50,50)', fontWeight: '600', fontSize: '12px' }}>{this.getDate(this.state.eventInfo.event_date)}</div>  
                </div>   

                <div  style={{ alignContent: 'left', alignItems: 'center', height: '50px', marginLeft: '40px', marginTop: '5px', display: 'flex'}}>
                <img src={this.state.eventInfo.profile_image} width="30px" height="30px" style={{ objectFit: 'cover', borderRadius: '25px'}} />
                <div onClick={this.goToPageProfile} style={{ cursor: 'pointer', textAlign: 'left',  marginLeft: '15px', color: 'rgb(50,50,50)', fontWeight: '600', fontSize: '12px' }}>{this.state.eventInfo.profile_name}</div>  
                </div>  

                <div  style={{ alignContent: 'center', alignItems: 'center', height: '50px', marginLeft: '50px', marginTop: '20px', display: 'flex'}}>
                
                <div onClick={() => this.selectedSection(0)} style={{ cursor: 'pointer', height: '30px'}}>
                    {this.state.selectedSection == 0 &&
                        <div><div style={{ fontWeight: '600', fontSize: '14px' }}>Info</div>  
                        <div style={{width: '100%', height: 2, marginTop: '8px', backgroundColor: 'rgb(50,50,50)'}}>
                        </div></div>
                    }
                    {this.state.selectedSection != 0 &&
                       <div style={{ }}><div style={{ fontWeight: '600', color: 'rgb(100,100,100)', fontSize: '14px' }}>Info</div>  
                       <div style={{width: '100%', height: 2, marginTop: '8px', backgroundColor: 'rgba(50,50,50,0)'}}>
                       </div></div>
                    }
                    
                </div>

                <div onClick={() => this.selectedSection(1)} style={{ cursor: 'pointer', marginLeft: '20px', height: '30px'}}>
                    {this.state.selectedSection == 1 &&
                        <div><div style={{ fontWeight: '600', fontSize: '14px' }}>Tickets</div>  
                        <div style={{width: '100%', height: 2, marginTop: '8px', backgroundColor: 'rgb(50,50,50)'}}>
                        </div></div>
                    }
                    {this.state.selectedSection != 1 &&
                       <div style={{ }}><div style={{ fontWeight: '600', color: 'rgb(100,100,100)', fontSize: '14px' }}>Tickets</div>  
                       <div style={{width: '100%', height: 2, marginTop: '8px', backgroundColor: 'rgba(50,50,50,0)'}}>
                       </div></div>
                    }
                    
                </div>

                {this.state.myTickets.length > 0 &&
                <div onClick={() => this.selectedSection(2)} style={{ cursor: 'pointer', marginLeft: '20px', height: '30px'}}>
                
                   <div style={{ }}><div style={{ fontWeight: '600', color: 'rgb(100,100,100)', fontSize: '14px' }}>My Tickets</div>  
                   <div style={{width: '100%', height: 2, marginTop: '8px', backgroundColor: 'rgba(50,50,50,0)'}}>
                   </div></div>
                
                
                </div>}


                </div>
                    <br />
                </div>
                <div style={{width: '100%', height: 300, left: 0, top: 50, position: 'absolute'}}>
                <img src={this.state.eventInfo.event_image} width="80%" height="300" style={{ borderRadius: '20px', objectFit: 'cover'}} />
                </div>

                {this.state.selectedSection == 0 &&
                 <div  style={{ alignContent: 'center', alignItems: 'center', marginTop: '80px', display: 'flex', flexDirection: 'column'}}>
           
                    <div style={{ whiteSpace: 'pre-line', width: '90%', color: 'rgb(50,50,50)', fontWeight: '400', fontSize: '14px' }}>{this.state.eventInfo.event_description}</div>  
                <br />
               </div>
                }

                {this.state.selectedSection == 1 &&
                <TicketList buyTicket={this.buyTicket} info={this.state.eventTickets} />
                }
              

                </div>
                
              </div>
              }


                {this.state.notExists && 
              <div  style={{ width: '100%', marginTop: '20px', textAlign: 'center', justifyContent: 'center', alignItems: 'center', height: '100vh', display: 'flex'}}>
              <img src={eventicon} width="40px" height="40px" style={{ objectFit: 'cover', borderRadius: '25px'}} />
              <div style={{ marginLeft: '20px', color: 'rgb(50,50,50)', fontWeight: '600', fontSize: '18px' }}>Event not found</div>  
              </div> 
              }  


<Drawer open={this.state.showMyTickets}
                    onRequestClose={this.closeMyTickets}
                    modalElementClass="modal">
            <div>
            <div  style={{  fontWeight: '600', alignContent: 'center', alignItems: 'center', marginTop: '10px', display: 'flex', flexDirection: 'column'}}>
            My Tickets
            </div>
             <div  style={{ alignContent: 'center', alignItems: 'center', marginTop: '0px', marginBottom: '20px', display: 'flex', flexDirection: 'column'}}>
            
             {
            this.state.myTickets.map((ticket, i) =>
             <div key={i} style={{ marginTop: '20px', width: '90%', borderRadius: '15px', backgroundColor: 'rgb(250,250,250)', minHeight: '50px'}}>
            
            <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ width: "65%" }}>
                <div style={{ fontWeight: '600', width: '100%', textAlign: 'left', marginLeft: '15px', fontSize: '16px', color: 'rgb(50,50,50)' }}>{ticket.event_name}</div>     
                <div style={{ fontWeight: '600', width: '100%', marginTop: '5px', textAlign: 'left', marginLeft: '15px', fontSize: '14px', color: 'rgb(50,50,50)' }}>{this.getDate(ticket.event_date)}</div>           
                <div style={{ fontWeight: '400', width: '100%', marginTop: '5px', textAlign: 'left', marginLeft: '15px', fontSize: '14px', color: 'rgb(100,100,100)' }}>{ticket.ticket_name}</div>  
                <div style={{ fontWeight: '400', width: '100%', marginTop: '5px', textAlign: 'left', marginLeft: '15px', fontSize: '14px', color: 'rgb(100,100,100)' }}>{ticket.unity_amount}¥</div>  
                <div style={{ fontWeight: '600', width: '100%', marginTop: '5px', textAlign: 'left', marginLeft: '15px', fontSize: '16px', color: 'rgb(0,0,0)' }}>x{ticket.quantity}</div>  
             
                    </div>
            <div style={{ height: "auto",display: 'flex', flexDirection: 'column', width: "35%", alignItems: 'flex-end', marginRight: '10px', }}>
            <QRCode
                size={256}
                style={{ height: "auto", maxWidth: "80px", width: "80px" }}
                value={ticket.ticket_code}
                viewBox={`0 0 256 256`}
                />

             <div style={{ fontWeight: '600', width: '80px', marginBottom: '10px', marginTop: '10px', textAlign: 'center', fontSize: '14px', color: 'rgb(50,50,50)' }}>{ticket.ticket_code}</div> 
            </div>
            </div>

           
            
             </div>
            )
             }
                 
             </div>
            
                             
           
            </div>
                 
            </Drawer>

               <Drawer
                    open={this.state.openLoader}
                        modalElementClass="modalLoader">
                         <div style={{textAlign: 'center', margin: '15px'}}>
            
                         <BoxLoading size="large" color="#007aff" />
                    </div>
                    </Drawer>  
            </div>
        )
    }
}

export default Event
