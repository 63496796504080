import React, { Component } from 'react';
import i18n from "i18next";
import Drawer from 'react-drag-drawer';
import './Popupstyle.css';
import { BoxLoading } from 'react-loadingg';
import info from './media/user.png';
import products from './media/calendaricon.png';
import QRCode from "react-qr-code";

export class Profile extends Component {


    constructor(props){
        super(props);
        this.state = { currentSection: 0, userInfo: null, tickets: [], showEditPassword: false,
             newpassword: '', password: '', errorPassword: false, errorField: false  };
        
    }

    componentDidMount () {
      
        this.getProfileInfo();
        
    }

    closeOrderDetails = () => {
        this.setState({ showOrderDetails: false });
    }

    getProfileInfo = () => {
        var sessiontoken = localStorage.getItem('session_token');
        const requestOptions = {
          method: 'POST',
          headers: new Headers({
            'Content-Type': 'application/x-www-form-urlencoded',
          }),
          body: JSON.stringify({
            token: sessiontoken
          })
          };
        
          const apiUrl = 'https://api.shami.app/public/v1/profile/details';
    
        fetch(apiUrl, requestOptions)
        .then(r => r.json())
        .then(r => {
          console.log(r);
          this.setState({ userInfo: r.user_info, tickets: r.tickets });
          
        }).catch(e => {
            
        });
      }

      showMyInfo = () => {
        this.setState({ currentSection: 0 });
      }

      showOrders = () => {
        this.setState({ currentSection: 1 });
      }

      logout = () => {
        var sessiontoken = localStorage.getItem('session_token');
        const requestOptions = {
          method: 'POST',
          headers: new Headers({
            'Content-Type': 'application/x-www-form-urlencoded',
          }),
          body: JSON.stringify({
            token: sessiontoken
          })
          };
        
          const apiUrl = 'https://api.shami.app/public/v1/profile/logout';
    
        fetch(apiUrl, requestOptions)
        .then(r => r.json())
        .then(r => {
            localStorage.removeItem('session_token');
        window.location.reload();
          
        }).catch(e => {
            
        });
      }


      changePassword = () => {
        this.setState({ errorField: false, errorPassword: false });
        if(!(this.state.newpassword.length>5 && this.state.password.length>5)){
          this.setState({ errorField: true });
          return;
        }
      
        this.setState({ openLoader: true });
        var sessiontoken = localStorage.getItem('session_token');
        const requestOptions = {
          method: 'POST',
          headers: new Headers({
            'Content-Type': 'application/x-www-form-urlencoded',
          }),
          body: JSON.stringify({
            token: sessiontoken,
            password: this.state.password,
            new_password: this.state.newpassword
          })
          };
        
          const apiUrl = 'https://api.shami.app/public/v1/profile/changepassword';
    
        fetch(apiUrl, requestOptions)
        .then(r => r.json())
        .then(r => {
         if(r.password == false){
          this.setState({ errorPassword: true });
         }else{
          this.closeLogin();
         } 
         this.setState({ openLoader: false });
          
        }).catch(e => {
          this.closeLogin();
          this.setState({ openLoader: false });
        });
      }

      showChangePassword = () => {
        this.setState({ showEditPassword: true, errorPassword: false, errorField: false });
      }


      closeLogin = () => {
        this.setState({ showEditPassword: false, errorPassword: false, errorField: false });
      }
   
      insertText = (e) => {
        this.setState({ errorField: false, errorPassword: false });
        var insert = String(e.target.value);   
        var fieldName = e.target.name;  
        this.setState({ [fieldName]: insert });     
      }


      selectTicket = (index) => {
        var newLocation = "/event/"+this.state.tickets[index].event_code;
        window.location.href = newLocation;
        return;
      }
  
      getDate = (string) => {
        var eventDate = new Date(string);
        var textualDate = new Intl.DateTimeFormat("en-US", {
            year: "numeric",
          }).format(eventDate);
          textualDate += '/';
          textualDate += new Intl.DateTimeFormat("en-US", {
            month: "2-digit",
          }).format(eventDate);
          textualDate += '/';
          textualDate += new Intl.DateTimeFormat("en-US", {
            day: "2-digit",
          }).format(eventDate);
          textualDate += ' ';
          textualDate += new Intl.DateTimeFormat("it-IT", {
            hour: "numeric",
            minute: "numeric"
          }).format(eventDate);
          
        return textualDate;
      }

    render() {
        return (
            <div style={{  }}>
            
            <br /> 

<div style={{ height: '80px', position: 'relative', backgroundColor: 'rgb(250,250,250)', display: 'flex', justifyContent: 'center', flexWrap: 'nowrap', overflowX: 'scroll', overflowY: 'hidden', flexDirection: 'row',scrollBehavior: 'smooth'}}>

<div onClick={() => this.showMyInfo()}  style={{  minWidth: '80px', height: '80px', position: 'relative', margin: '0px', cursor: 'pointer' }}>
<div style={{width: 80, height: 30, left: 0, top: 8, position: 'absolute'}}>
<img src={info} width="25px" height="25px" style={{ borderRadius: '0px'}} />
</div>
<div style={{width: 80, height: 40, left: 0, top: 42, position: 'absolute'}}>
{this.state.currentSection == 0 &&
<div style={{width: 80, height: 40, position: 'absolute', textAlign: 'center', color: 'rgb(50,50,50)', fontSize: 12,   fontWeight: '600', wordWrap: 'break-word'}}>{i18n.t('my.info')}</div>
}
{this.state.currentSection != 0 &&
<div style={{width: 80, height: 40, position: 'absolute', textAlign: 'center', color: 'rgb(50,50,50)', fontSize: 12,   fontWeight: '400', wordWrap: 'break-word'}}>{i18n.t('my.info')}</div>
}

</div>
{this.state.currentSection == 0 &&
<div style={{width: 50, height: 2, left: 15, top: 70, position: 'absolute', backgroundColor: 'rgb(50,50,50)'}}>
</div> }
</div>

<div onClick={() => this.showOrders()}  style={{  minWidth: '80px', height: '80px', position: 'relative', margin: '0px', cursor: 'pointer' }}>
<div style={{width: 80, height: 30, left: 0, top: 8, position: 'absolute'}}>
<img src={products} width="25px" height="25px" style={{ borderRadius: '0px'}} />
</div>
<div style={{width: 80, height: 40, left: 0, top: 42, position: 'absolute'}}>
{this.state.currentSection == 1 &&
<div style={{width: 80, height: 40, position: 'absolute', textAlign: 'center', color: 'rgb(50,50,50)', fontSize: 12,   fontWeight: '600', wordWrap: 'break-word'}}>{i18n.t('my.tickets')}</div>
}
{this.state.currentSection != 1 &&
<div style={{width: 80, height: 40, position: 'absolute', textAlign: 'center', color: 'rgb(50,50,50)', fontSize: 12,   fontWeight: '400', wordWrap: 'break-word'}}>{i18n.t('my.tickets')}</div>
}

</div>
{this.state.currentSection == 1 &&
<div style={{width: 50, height: 2, left: 15, top: 70, position: 'absolute', backgroundColor: 'rgb(50,50,50)'}}>
</div> }
</div>

</div>

{(this.state.userInfo != null && this.state.currentSection==0) &&
 <div style={{ display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "stretch",
  flexWrap: 'wrap',
  flexShrink: 1,}}>
<div  style={{marginTop: '20px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <input 
                style={{ border: 'none', color: 'rgb(50,50,50)', fontWeight: 'normal', height: '40px', width: '250px', borderRadius: '8px', 
                backgroundColor: 'rgb(255,255,255)', border: 'none', outlineWidth: '0', fontSize: this.state.componentFontSize, textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}} type="text"  
                name="name"
                contentEditable="false"
                value={this.state.userInfo.first_name}
                placeholder={i18n.t('name')} 
                onChange={this.insertText}
                readOnly
                />
               </div>

               <div  style={{marginTop: '10px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <input 
                style={{ border: 'none', color: 'rgb(50,50,50)', fontWeight: 'normal', height: '40px', width: '250px', borderRadius: '8px', 
                backgroundColor: 'rgb(255,255,255)', border: 'none', outlineWidth: '0', fontSize: this.state.componentFontSize, textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}} type="text"  
                name="surname"
                contentEditable="false"
                value={this.state.userInfo.last_name}
                placeholder={i18n.t('surname')} 
                onChange={this.insertText}
                readOnly
                />
               </div>

               <div  style={{marginTop: '10px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <input 
                style={{ border: 'none', color: 'rgb(50,50,50)', fontWeight: 'normal', height: '40px', width: '250px', borderRadius: '8px', 
                backgroundColor: 'rgb(255,255,255)', border: 'none', outlineWidth: '0', fontSize: this.state.componentFontSize, textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}} type="text"  
                name="email"
                contentEditable="false"
                value={this.state.userInfo.email}
                placeholder={i18n.t('email')} 
                onChange={this.insertText}
                readOnly
                />
               </div>

               <div  style={{marginTop: '50px',  textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <p style={{ cursor: 'pointer',  fontWeight: 'bold', height: '40px', width: '250px', borderRadius: '8px', backgroundColor: 'rgb(255,255,255)', fontSize: '14px', 
            textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'rgb(50,50,50)'}}
            onClick={this.showChangePassword}
            >{i18n.t('change.password')}</p>
                    </div>
                    <div  style={{marginTop: '0px',  textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <p style={{ cursor: 'pointer',  fontWeight: 'bold', height: '40px', width: '250px', borderRadius: '8px', backgroundColor: '#E82E15', fontSize: '14px', 
            textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white'}}
            onClick={this.logout}
            >Logout</p>
                    </div>
    </div>
    }


{(this.state.tickets.length == 0 && this.state.currentSection==1) &&
<div><br/><br/>{i18n.t('no.tickets')}</div>
}
{(this.state.tickets.length != 0 && this.state.currentSection==1) &&
  <div  style={{ alignContent: 'center', alignItems: 'center', marginTop: '10px', display: 'flex', flexDirection: 'column'}}>
           
{
            this.state.tickets.map((ticket, i) =>
             <div key={i} onClick={() => this.selectTicket(i)} style={{ cursor: 'pointer', marginTop: '20px', width: '70%', maxWidth: '500px', borderRadius: '15px', backgroundColor: 'rgb(255,255,255)', minHeight: '50px'}}>
            
            <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ width: "65%" }}>
                <div style={{ fontWeight: '600', width: '100%', textAlign: 'left', marginLeft: '15px', fontSize: '16px', color: 'rgb(50,50,50)' }}>{ticket.event_name}</div>  
                <div style={{ fontWeight: '600', width: '100%', marginTop: '5px', textAlign: 'left', marginLeft: '15px', fontSize: '14px', color: 'rgb(50,50,50)' }}>{this.getDate(ticket.event_date)}</div>             
                <div style={{ fontWeight: '400', width: '100%', marginTop: '5px', textAlign: 'left', marginLeft: '15px', fontSize: '14px', color: 'rgb(100,100,100)' }}>{ticket.ticket_name}</div>  
                <div style={{ fontWeight: '400', width: '100%', marginTop: '5px', textAlign: 'left', marginLeft: '15px', fontSize: '14px', color: 'rgb(100,100,100)' }}>{ticket.unity_amount}¥</div>  
                <div style={{ fontWeight: '600', width: '100%', marginTop: '5px', textAlign: 'left', marginLeft: '15px', fontSize: '16px', color: 'rgb(0,0,0)' }}>x{ticket.quantity}</div>  
             
                    </div>
            <div style={{ height: "auto",display: 'flex', flexDirection: 'column', width: "35%", alignItems: 'flex-end', marginRight: '10px', }}>
            <QRCode
                size={256}
                style={{ height: "auto", maxWidth: "80px", width: "80px" }}
                value={ticket.ticket_code}
                viewBox={`0 0 256 256`}
                />

             <div style={{ fontWeight: '600', width: '80px', marginBottom: '10px', marginTop: '10px', textAlign: 'center', fontSize: '14px', color: 'rgb(50,50,50)' }}>{ticket.ticket_code}</div> 
            </div>
            </div>

           
            
             </div>
            )
             }
             </div>
            }




                    <Drawer
                    open={this.state.openLoader}
                        modalElementClass="modalLoader">
                         <div style={{textAlign: 'center', margin: '15px'}}>
            
                         <BoxLoading size="large" color="#007aff" />
                    </div>
                    </Drawer>

                    <Drawer
                    open={this.state.showEditPassword}
                        onRequestClose={this.closeLogin}
                        modalElementClass="modalMobileLogin2">
                            <div>
                            <div style={{textAlign: 'center', margin: '15px'}}>
            <span style={{color: 'rgb(50,50,50)', fontSize: 20, fontWeight: '700', wordWrap: 'break-word'}}>{i18n.t('change.password')}</span></div>
           
            <div  style={{marginTop: '10px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <input 
                style={{ border: 'none', color: 'rgb(50,50,50)', fontWeight: 'normal', height: '40px', width: '250px', borderRadius: '8px', 
                backgroundColor: 'rgb(250,250,250)', border: 'none', outlineWidth: '0', fontSize: this.state.componentFontSize, textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}} type="password"  
                name="password"
                value={this.state.password}
                placeholder={i18n.t('password')} 
                onChange={this.insertText}
                />
               </div>

               <div  style={{marginTop: '10px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <input 
                style={{ border: 'none', color: 'rgb(50,50,50)', fontWeight: 'normal', height: '40px', width: '250px', borderRadius: '8px', 
                backgroundColor: 'rgb(250,250,250)', border: 'none', outlineWidth: '0', fontSize: this.state.componentFontSize, textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}} type="password"  
                name="newpassword"
                value={this.state.newpassword}
                placeholder={i18n.t('new.password')} 
                onChange={this.insertText}
                />
               </div>
            
            

            {this.state.errorField &&
            <div style={{marginTop: '10px', height: '100%', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <br/><br/>
            <span style={{color: 'red', fontSize: 12, fontWeight: '400', wordWrap: 'break-word'}}>{i18n.t('error.field')}</span></div>
            }
            {this.state.errorPassword &&
            <div style={{marginTop: '10px', height: '100%', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <br/><br/>
            <span style={{color: 'red', fontSize: 12, fontWeight: '400', wordWrap: 'break-word'}}>{i18n.t('error.password')}</span></div>
            }
                          
                    <div  style={{marginTop: '0px',  textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <p style={{ cursor: 'pointer',  fontWeight: 'bold', height: '40px', width: '250px', borderRadius: '8px', backgroundColor: '#007aff', fontSize: '14px', 
            textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white'}}
            onClick={this.changePassword}
            >{i18n.t('confirm')}</p>
                    </div>
                    <div onClick={this.closeLogin} style={{  textAlign: 'center', marginLeft: '20px', marginRight: '20px', marginBottom: '10px'}}><span style={{cursor: 'pointer', color: 'black', fontSize: 12, fontWeight: '500', wordWrap: 'break-word'}}>{i18n.t('close')}</span></div>
   
                    </div>
                    </Drawer>

                  

            </div>
        )
    }
}

export default Profile
